<template>

	<div style="padding-top: 20px">
		<div class="container">
			<div class="row">
				<div class="col-12">

					<div style="margin: auto;">
						<div style="font-size: 20px;" class="alert" :class="{'alert-danger': n.is_important == 'y', 'alert-info': n.is_important == 'n' }" v-for="n in notifications" :key="n">
							<div style="float: right; cursor: pointer; font-weight: bold;" class="btn btn-primary btn-sm" @click="seeNotification(n)">OK</div>
							{{ n.message }}
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<div class="text-center" style="font-size: 22px; font-weight: bold; color: #444;">
			<a class="pull-right text-info" href="https://www.youtube.com/watch?v=MvCVoDtg9lI" target="_blank" style="font-size: 20px; margin-top: 5px; margin-right: 15px; text-decoration: none;">
				<i style="color: #ff0000" class="fa fa-youtube-play"></i> Help
			</a>
			<a class="pull-right text-primary" href="https://teleradio.in/dicomsetup.zip" target="_blank" style="font-size: 20px; margin-top: 5px; margin-right: 15px; text-decoration: none;">
				<i class="fa-solid fa-download"></i> DICOM Setup
			</a>
			<div class="pull-right" :class="{'text-success': bages.reviewPercent <= 2, 'text-warning': bages.reviewPercent > 2 && bages.reviewPercent <= 3, 'text-danger': bages.reviewPercent > 3}" style="margin-right: 36px; font-size: 20px; margin-top: 5px;">
				Review: {{ bages.reviewPercent }}%
			</div>
			Welcome to {{ bages.centerName }} (Center ID: {{bages.centerId}})
		</div>
		<div style="height: 20px;"></div>
		<div class="container-fluid">
			<div class="row">

				<div @click="$router.push('/center/xray-report')" class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.xray_reports }}</div>
							<div>Number of XRay case today</div>
						</div>
					</div>
				</div>
				<div @click="$router.push('/center/ecg-report')" class="col-sm-6 col-lg-3">
					<div class="card text-white bg-primary">
						<div class="card-body">
							<div class="text-value">{{ bages.ecg_reports }}</div>
							<div>Number of ECG case today</div>
						</div>
					</div>
				</div>
				<div @click="$router.push('/center/payment')" class="col-sm-6 col-lg-3">
					<div class="card text-white" :class="{'bg-danger': 'balance' in bages  && bages.balance <= 300, 'bg-success': bages.balance > 300 }">
						<div class="card-body clearfix">
							<button v-if="'balance' in bages  && bages.balance <= 300" type="button" class="btn btn-info btn-sm pull-right"><i class="fa fa-arrow-right"></i> Recharge Now</button>
							<div class="text-value">&#8377;{{ bages.balance }}</div>
							<div>Balance Amount</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">&#8377;{{ bages.last_payment?.amount }} <span
									v-if="bages.last_payment?.date">({{ format(bages.last_payment?.date, "DD/MM/YY") }})</span>
							</div>
							<div>Last recharge</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid" style="margin-top: 15px;">
			<div class="row">
				<div class="col-sm-8">
					<canvas id="myChart" style="width:100%;"></canvas>
				</div>
				<div class="col-sm-4">
					<a href="http://labsystem.in" target="_blank">
						<div style="position: absolute; padding: 1px">
							<div style="background: #ffcd09; color: rgb(255, 255, 255); padding: 0 5px;">Ads</div>
						</div>
						<img style="display: block; width: 100%;" :src="adsImage" alt="">
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			bages: {},
			notifications: [],

			adsImage: '',
		}
	},
	async created() {
		this.bages = await this.get("center/dashboard-bages");

		if (this.bages.center_ads) this.adsImage = window.apiOrigin + '/images/center/' + this.bages.center_ads;
	},
	async mounted() {
		let getDate = (adjust) => {
			let time = new Date();
			time.setTime(time.getTime() + adjust * 24 * 60 * 60 * 1000);

			let day = this.format(time, "dddd");

			let date = this.format(time, "YYYY-MM-DD");
			date = new Date(date);
			date.setTime(date.getTime() - 5.5 * 60 * 60 * 1000);
			let start = date.toISOString();

			time = new Date();
			time.setTime(time.getTime() + (adjust + 1) * 24 * 60 * 60 * 1000);
			date = this.format(time, "YYYY-MM-DD");
			date = new Date(date);
			date.setTime(date.getTime() - 5.5 * 60 * 60 * 1000);
			let end = date.toISOString();

			return {
				start,
				end,
				day
			}
		}
		let dates = [];
		for (let i = 0; i < 7; i++) {
			dates.unshift(getDate(i * -1));
		}

		let data = await this.post('center/load-xray-reports-graph-data', {
			dates,
		});

		var xValues = [];//"Italy", "France", "Spain", "USA", "Argentina", "sdlkj", "ksdj"
		var yValues = [];//55, 49, 44, 24, 30, 60, 90
		for (let date of data) {
			xValues.push(date.day);
			yValues.push(date.data);
		}


		var barColors = ["red", "green", "blue", "orange", "brown", "#60ccf0", "#1e5373"];

		new window.Chart("myChart", {
			type: "bar",
			data: {
				labels: xValues,
				datasets: [{
					backgroundColor: barColors,
					data: yValues
				}]
			},
			options: {
				legend: { display: false },
				title: {
					display: true,
					text: "Reports"
				}
			}
		});

		this.notifications = await this.get('center/load-unseen-notifications');

	},
	methods: {
		async seeNotification(n){
			let i = this.notifications.indexOf(n);
			this.notifications.splice(i,1);
			await this.post('center/see-notification', {
				id: n.id
			});
		}
	}
}
</script>
<style scoped>
.card-body {
	flex: 1 1 auto;
	padding: 1.25rem;
}

.text-value {
	font-size: 1.3125rem;
	font-weight: 600;
}
</style>